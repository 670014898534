@media only screen and (max-width: 470px) {
  
  .navbar {
    height: 70px !important;
    grid-template-columns: 3fr 4fr !important;
  }

  .options > button {
    font-size: 12px !important;
  }

  .left > .title {
    display: flex;
    align-items: center;
    font-size: 16px !important;
  }

  .entry > .actions {
    grid-template-columns: 1fr 1fr;
    padding: 4px !important;
    padding-left: 6px !important;
  }
  
  .actions > a {
    padding: 16px 8px;
    color: white;
  }

  .entry > .title {
    padding: 2px 14px !important;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  }

  .entry > .title > span {
    font-size: 14px !important;
  }

  .list > .entries {
    margin-top: 70px !important;
  }
}

@media only screen and (max-width: 550px) and (min-width: 470px){
  
  .navbar {
    height: 70px !important;
  }

  .left > .title {
    display: flex;
    align-items: center;
    font-size: 18px !important;
  }

  .entry > .actions {
    grid-template-columns: 1fr 1fr;
    padding: 4px !important;
    padding-left: 6px !important;
  }
  
  .actions > a {
    padding: 16px 8px;
    color: white;
  }

  .entry > .title {
    padding: 2px 14px !important;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  }

  .entry > .title > span {
    font-size: 18px !important;
  }

  .list > .entries {
    margin-top: 70px !important;
  }
}

@media only screen and (max-width: 820px) and (min-width: 550px) {
  
  .navbar {
    height: 70px !important;
  }

  .left > .title {
    display: flex;
    height: 100%;
    align-items: center;
    font-size: 20px !important;
    padding: 0px 20px !important;
  }

  .entry > .actions {
    grid-template-columns: 1fr 1fr;
    padding: 4px !important;
    padding-left: 6px !important;
  }
  
  .actions > a {
    padding: 16px 8px;
    color: white;
  }

  .entry > .title {
    padding: 2px 14px !important;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  }

  .entry > .title > span {
    font-size: 18px !important;
  }

  .list > .entries {
    margin-top: 70px !important;
  }
}

.app {
  width: 100vw;
  height: 100vh;
  background-color: #4158D0;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
}

.navbar {
  z-index: 255;
  height: 60px;
  width: 100vw;
  position: sticky;
  top: 0px;

  display: grid;

  grid-template-columns: 1fr 300px;
  grid-template-areas: 
  "left right";

  background-color: rgba(34, 68, 85, 0.4);
  box-shadow: 0px 2px 6px 0px #00000070;
  backdrop-filter: blur(3px);
}

.navbar > .left {
  grid-area: left;
}

.left > .title {
  font-family: 'Klee One', cursive;
  font-weight: 600;
  padding: 8px 16px;
  color: white;

  font-size: 28px;
  text-shadow: 3px 2px 4px #555;
}

.navbar > .right {
  grid-area: right;
  padding: 8px;
}

.right > .options {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
}

.options > button {
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  background-color: #fff5;
  box-shadow: inset 0px 2px 6px 0px #00000070;
  text-shadow: -1px -1px 0 #555, 1px -1px 0 #555, -1px 1px 0 #555, 1px 1px 0 #555;

  color: white;

  width: 100%;
  height: 100%;
  border: none;
  border-radius: 12px;

  font-size: 16px;

  transition: 200ms;
}

.options > button:hover {
  box-shadow: inset 0px 3px 8px 0px #00000070;
  background-color: #bce8;
}

.options > button:focus {
  box-shadow: inset 0px 4px 10px 0px #00000090;
  background-color: #cdfa;
}

.list {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;

  overflow-y: scroll;
}

.list > .entries {
  margin-top: 60px;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: #1341;
}

::-webkit-scrollbar-thumb {
  background: #52357636;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #1d05555b;
}

/*
font-family: 'Indie Flower', cursive;
font-family: 'Klee One', cursive;
font-family: 'Montserrat', sans-serif;
font-family: 'Nunito', sans-serif;
font-family: 'Playfair Display', serif;
font-family: 'Quicksand', sans-serif;
*/

.entries > .entry {
  width: 100%;
  height: 60px;

  display: grid;

  grid-template-columns: 80px 2fr 40px;
  grid-template-areas: 
  "actions title info";

  border-bottom: 1px solid #aaa7;
}

.entries > .entry:nth-child(2n) {
  background-color: rgba(0, 0, 0, 0.05)
}

.entry > .actions {
  grid-area: actions;
  display: grid;

  grid-template-columns: 1fr 1fr;
  padding: 4px;
  padding-left: 12px;
}

.actions > a {
  padding: 16px 8px;
  color: white;
}

.entry > .information {
  padding: 18px 0px;
}

.information > a {
  padding: 16px 8px;
  color: white;
}

.entry > .title {
  padding: 4px 18px;
  display: flex;
  align-items: center;
}

.entry > .title > span {
  font-size: 22px;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}